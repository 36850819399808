import React from 'react';
import { Link } from 'gatsby';

const NotFound = () => {
  if (typeof window !== `undefined`) {
    const partnersReferringPartnersRegex = /\/partners\/ref\/([A-Za-z0-9]+)/gi;
    let found = partnersReferringPartnersRegex.exec(window.location.href);

    if (found && found.length > 1) {
      return <meta httpEquiv="refresh" content={`0; url=/partners?partnerId=${found[1]}`} />;
    }

    const partnersRegex = /\/partners\/([A-Za-z0-9]+)/gi;
    found = partnersRegex.exec(window.location.href);

    if (found && found.length > 1) {
      return <meta httpEquiv="refresh" content={`0; url=/partner?partnerId=${found[1]}`} />;
    }

    const partnersRegex2 = /\/partner\/([A-Za-z0-9]+)/gi;
    found = partnersRegex2.exec(window.location.href);

    if (found && found.length > 1) {
      return <meta httpEquiv="refresh" content={`0; url=/partner?partnerId=${found[1]}`} />;
    }

    if (found && found.length > 1) {
      return <meta httpEquiv="refresh" content={`0; url=/refer?partnerId=${found[1]}`} />;
    }

    const enrollCX = /\/enrollcx\/([A-Za-z0-9]+)/gi;
    found = enrollCX.exec(window.location.href);

    if (found && found.length > 1) {
      return <meta httpEquiv="refresh" content={`0; url=/enrollcx?partnerId=${found[1]}`} />;
    }

    const enroll805 = /\/enroll805\/([A-Za-z0-9]+)/gi;
    found = enroll805.exec(window.location.href);

    if (found && found.length > 1) {
      return <meta httpEquiv="refresh" content={`0; url=/enroll805?partnerId=${found[1]}`} />;
    }

    const referRegex = /\/refer\/([A-Za-z0-9]+)/gi;
    found = referRegex.exec(window.location.href);

    if (found && found.length > 1) {
      return <meta httpEquiv="refresh" content={`0; url=/refer?partnerId=${found[1]}`} />;
    }

    return <meta httpEquiv="refresh" content={`0; url=/`} />;
  }

  return (
    <></>
  );
};

export default NotFound;
